<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Advanced Options'"
          :closed="false"
        >
          <div class="ml-2 bg-gray-800">
            <BlockGroup
              :label="'Base Map'"
              :level="2"
            >
              <InputWrapper
                type="toggle"
                :label="'Map Enabled'"
                :value="mapEnabled"
                @change="mapEnabled = $event"
              />

              <InputWrapper
                type="texture-asset"
                :label="'Map Texture'"
                :value="mapValue"
                @change="onChangeTextureAsset('map', $event)"
              />
            </BlockGroup>

            <BlockGroup
              :label="'Alpha'"
              :level="2"
            >
              <InputWrapper
                type="toggle"
                :label="'Transparent'"
                :value="transparent"
                @change="transparent = $event"
              />

              <InputWrapper
                type="slider"
                :label="'Opacity'"
                :extra="'Only affects color if material is not transparent'"
                :min="0"
                :max="1"
                :step="0.001"
                :value="opacity"
                @change="opacity = $event"
              />

              <InputWrapper
                type="toggle"
                :label="'Alpha Map Enabled'"
                :value="alphaMapEnabled"
                @change="alphaMapEnabled = $event"
              />

              <InputWrapper
                type="texture-asset"
                :label="'Alpha Map'"
                :value="alphaValue"
                @change="onChangeTextureAsset('alphaMap', $event)"
              />
            </BlockGroup>

            <BlockGroup
              :label="'Emissive'"
              :level="2"
            >
              <InputWrapper
                type="color"
                :label="'Emissive Color'"
                :value="emissiveColor"
                @change="emissiveColor = $event"
              />

              <InputWrapper
                type="slider"
                :label="'Emissive Intensity'"
                :min="0"
                :max="1"
                :step="0.001"
                :value="emissiveIntensity"
                @change="emissiveIntensity = $event"
              />

              <InputWrapper
                type="toggle"
                :label="'Emissive Map Enabled'"
                :value="emissiveMapEnabled"
                @change="emissiveMapEnabled = $event"
              />

              <InputWrapper
                type="texture-asset"
                :label="'Emissive Map'"
                :value="emissiveValue"
                @change="onChangeTextureAsset('emissiveMap', $event)"
              />
            </BlockGroup>

            <BlockGroup
              :label="'Normal Map'"
              :level="2"
            >
              <InputWrapper
                type="toggle"
                :label="'Normal Map Enabled'"
                :value="normalMapEnabled"
                @change="normalMapEnabled = $event"
              />

              <InputWrapper
                type="texture-asset"
                :label="'Normal Map'"
                :value="normalValue"
                @change="onChangeTextureAsset('normalMap', $event)"
              />

              <InputWrapper
                type="slider"
                :label="'Normal Map Scale X'"
                :min="0"
                :max="1"
                :value="normalScaleX"
                @change="normalScaleX = $event"
              />

              <InputWrapper
                type="slider"
                :label="'Normal Map Scale Y'"
                :min="0"
                :max="1"
                :value="normalScaleY"
                @change="normalScaleY = $event"
              />
            </BlockGroup>

            <BlockGroup
              :label="'Ambient Occlusion'"
              :level="2"
            >
              <InputWrapper
                type="toggle"
                :label="'AO Map Enabled'"
                :value="aoMapEnabled"
                @change="aoMapEnabled = $event"
              />

              <InputWrapper
                type="texture-asset"
                :label="'AO Map'"
                :value="aoValue"
                @change="onChangeTextureAsset('aoMap', $event)"
              />

              <InputWrapper
                type="number"
                :min="0"
                :step="0.1"
                :label="'AO Map Intensity'"
                :value="aoMapIntensity"
                @change="aoMapIntensity = $event"
              />
            </BlockGroup>
            <!--
          <BlockGroup
            v-bind:label="'Bump Map'"
            v-bind:level="2">
            <InputWrapper
              type="toggle"
              v-bind:label="'Enabled'"
              v-bind:value="true"
              v-on:change="onChange('map', $event)" />
          <InputWrapper
            type="texture-asset"
            v-bind:label="'Bump Map'"
            v-bind:value="aoValue"
            v-on:change="onChange('aoMap', $event)" />
            <InputWrapper
              type="slider"
              v-bind:min="0"
              v-bind:max="1"
              v-bind:label="'Bump Map Scale'"
              v-bind:value="node.bumpMapScale"
              v-on:change="onChange('alphaMap', $event)" />
          </BlockGroup>

          <BlockGroup
            v-bind:label="'Displace Map'"
            v-bind:level="2">
            <InputWrapper
              type="toggle"
              v-bind:label="'Enabled'"
              v-bind:value="true"
              v-on:change="onChange('map', $event)" />
            <InputWrapper
              type="texture-asset"
              v-bind:label="'Displace Map'"
              v-bind:value="aoValue"
              v-on:change="onChange('aoMap', $event)" />
            <InputWrapper
              type="slider"
              v-bind:label="'Displace Map Scale'"
              v-bind:value="node.displaceMapScale"
              v-on:change="onChange('alphaMap', $event)" />
          </BlockGroup>

          <BlockGroup
            v-bind:label="'Env Map'"
            v-bind:level="2">
            <InputWrapper
              type="toggle"
              v-bind:label="'Enabled'"
              v-bind:value="true"
              v-on:change="onChange('map', $event)" />
            <InputWrapper
              type="texture-asset"
              v-bind:label="'Env Map'"
              v-bind:value="aoValue"
              v-on:change="onChange('aoMap', $event)" />
            <InputWrapper
              type="slider"
              v-bind:label="'Env Map Reflectivity'"
              v-bind:value="node.envMapReflectitivy"
              v-on:change="onChange('alphaMap', $event)" />
          </BlockGroup> -->
          </div>
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'MaterialTexturesBlock',

  mixins: [mixin],

  data () {
    return {
      texture: null
    }
  },

  computed: {

    emissiveColor: {
      get () {
        // Emissive Color _might_ have a value in the node's user data
        if (this.node.userData.emissiveColor !== undefined) {
          let color = this.gui.colorFloatToColor(this.node.userData.emissiveColor)
          this.node.emissive = color
          delete this.node.userData.emissiveColor
        }

        return '#' + this.node.emissive.getHexString()
      },
      set (val) {
        this.gui.updateNode(() => {
          val = parseInt(val.substr(1), 16)
          this.node.emissive.setHex(val)
        })
      }
    },

    emissiveIntensity: {
      get () {
        // emissiveIntensity _might_ have a value in the node's user data
        if (this.node.userData.emissiveIntensity !== undefined) {
          this.node.emissiveIntensity = this.node.userData.emissiveIntensity
          delete this.node.userData.emissiveIntensity
        }

        return this.node.emissiveIntensity
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.emissiveIntensity = val
        })
      }
    },

    opacity: {
      get () {
        return this.node.opacity
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.opacity = val
        })
      }
    },

    transparent: {
      get () {
        return this.node.transparent
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.transparent = val
        })
      }
    },

    mapEnabled: {
      get () {
        return this.node.map !== null
      },
      set (val) {
        this.updateMapEnabled(val, 'map')
      }
    },

    alphaMapEnabled: {
      get () {
        return this.node.alphaMap !== null
      },
      set (val) {
        this.updateMapEnabled(val, 'alphaMap')
      }
    },

    emissiveMapEnabled: {
      get () {
        return this.node.emissiveMap !== null
      },
      set (val) {
        this.updateMapEnabled(val, 'emissiveMap')
      }
    },

    aoMapEnabled: {
      get () {
        return this.node.aoMap !== null
      },
      set (val) {
        this.updateMapEnabled(val, 'aoMap')
      }
    },

    aoMapIntensity: {
      get () {
        return this.node.aoMapIntensity
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.aoMapIntensity = val
        })
      }
    },

    normalMapEnabled: {
      get () {
        return this.node.normalMap !== null
      },
      set (val) {
        this.updateMapEnabled(val, 'normalMap')
      }
    },

    normalScaleX: {
      get () {
        return this.node.normalScale.x
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.normalScale.x = val
        })
      }
    },

    normalScaleY: {
      get () {
        return this.node.normalScale.y
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.normalScale.y = val
        })
      }
    },

    mapValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    bumpValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    emissiveValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    alphaValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    aoValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    normalValue () {
      return {
        enabled: true,
        hasTexture: true
      }
    },

    map () {
      return this.node.map
    },

    mapImg () {
      if (this.map !== null) return this.map.image
      return null
    },

    hasTexture () {
      return this.mapImg !== null
    }

  },

  methods: {

    updateMapEnabled (val, key) {
      if (val === true) {
        this.revertMap(key)
      } else {
        this.storeMap(key)
      }
    },

    storeMap (key) {
      this.gui.updateNode(() => {
        let oldKey = '_old' + key
        this.node[oldKey] = this.node[key]
        this.node[key] = null
        this.node.needsUpdate = true
      })
    },

    revertMap (key) {
      this.gui.updateNode(() => {
        let oldKey = '_old' + key
        if (this.node[oldKey] !== undefined) {
          this.node[key] = this.node[oldKey]
          delete this.node[oldKey]
          this.node.needsUpdate = true
        }
      })
    },

    onChangeTexture (attribute, event) {
      let files = event.target.files
      let file = files[0]

      var reader = new window.FileReader()
      reader.addEventListener('load', (event) => {
        let img = event.target.result

        this.gui.loadImageAndSetToObject(img, this.node, attribute)

        // Also set the toggle at the same time
        let toggleKey = attribute + 'Enabled'

        this[toggleKey] = true
      })

      reader.readAsDataURL(file)
    },

    onChangeTextureAsset (attribute, event) {
      let img = event.url

      this.gui.loadImageAndSetToObject(img, this.node, attribute)

      // Also set the toggle at the same time
      let toggleKey = attribute + 'Enabled'

      this[toggleKey] = true

      // This also needs to emit a special event to update the map relationship
      // in the resources array

      let mapName = attribute.replace('Map', '')
      let key = this.node.name + '_' + mapName

      let type = 'image/jpg'
      if (img.includes('.png')) type = 'image/png' // Gross

      this.$bus.$emit('scene:model:resource-association-updated', { key: key, resource_id: event.id, type: type })
    }
  }

}

</script>
